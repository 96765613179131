import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Checkbox, Form, Grid, Input } from 'semantic-ui-react';
import { UIDateFormat, UITimeFormat } from '../../config/dates';
import RefreshSlider from './RefreshSlider';


class CompanyFilters extends React.Component {

    useCurrentDateTime = () => {
      this.props.setUseCurrentDateTime()
    }

    handleChange = (event) => {
      let name = event.target.name;
      let value = event.target.value;
      this.props.onChange(name, value)
    }

    handleSessionIDChange = (event) => {
        const value = event.target.value > 999999999 ? 999999999 : event.target.value;
        this.props.onChange("sessionId", value)
    }

    handleShowEvents = () => {
      this.props.onChange('includeEvents', !this.props.includeEvents)
    }

    updateDate(name, date, time) {

      this.props.onChange(
        name,
        moment(`${date} ${time}`, 'DD-MM-YYYY HH:mm').toISOString()
      );

    }

    handleDateChange = (event, {name, value}) => {
      this.setState({[name]: value}, () => {
        let time = name === 'startDate' ? moment(this.props.startDate).format(UITimeFormat) : moment(this.props.endDate).format(UITimeFormat);
        this.updateDate(name, value, time);
      });
    }

    handleTimeChange = (event, {name, value}) => {
      this.setState({[name]: value}, () => {
        let key = name === 'startTime' ? 'startDate' : 'endDate';
        this.updateDate(key, this.state[key], value);
      });
    }

    convertParentDate(key) {
      return moment(this.props[key]).format(UIDateFormat)
    }

    convertParentTime(key) {
      
      return moment(this.props[key]).format(UITimeFormat)
    }

    render() {
      return (
          <Form id="companyFilters">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>

                  <Form.Group>
                     <Form.Field inline>
                      <label>From</label>
                      <DateInput
                        className="ui input margin-right"
                        name="startDate"
                        placeholder="Date"
                        value={this.convertParentDate('startDate')}
                        iconPosition="left"
                        onChange={this.handleDateChange}
                        animation="fade"
                        popupPosition="bottom center"
                        closable
                        autoComplete="off"
                      />
                      <TimeInput
                        className="ui input"
                        name="startTime"
                        placeholder="Time"
                        value={this.convertParentTime('startDate')}
                        iconPosition="left"
                        onChange={this.handleTimeChange}
                        animation="fade"
                        popupPosition="bottom center"
                        closable
                        style={{width: '90px'}}
                        autoComplete="off"
                      />
                    </Form.Field>

                    { this.props.hideTransactionFilter && <Form.Field><Checkbox name="zeroBetSessions" label='Zero Bet Sessions Only' checked={this.props.zeroBetSessions} onChange={() => this.props.onChange("zeroBetSessions", !this.props.zeroBetSessions)} /></Form.Field> }

                    { this.props.hasShowEvents && <Form.Field inline>
                      <Checkbox label='Show Events' checked={this.props.includeEvents} onChange={this.handleShowEvents} name="includeEvents"/>
                    </Form.Field> }
                    <RefreshSlider style={{ marginLeft: "8px"}}
                     refresh={this.props.refresh}
                     disabled={this.props.refreshDisabled}
                     loading={this.props.loading}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field inline>
                      <label>To</label>

                    { !this.props.useCurrentDateTime &&
                      <span>
                        <DateInput
                          className="ui input margin-right"
                          name="endDate"
                          placeholder="Date"
                          value={this.convertParentDate('endDate')}
                          iconPosition="left"
                          onChange={this.handleDateChange}
                          animation="fade"
                          popupPosition="bottom center"
                          closable
                          autoComplete="off"
                        />
                        <TimeInput
                          className="ui input"
                          name="time"
                          placeholder="Time"
                          value={this.convertParentTime('endDate')}
                          iconPosition="left"
                          onChange={this.handleTimeChange}
                          animation="fade"
                          popupPosition="bottom center"
                          closable
                          style={{width: '90px'}}
                          autoComplete="off"
                        />
                      </span>
                    }
                    </Form.Field>

                    <Form.Field inline>
                      <Checkbox checked={this.props.useCurrentDateTime} label='Current Time/Date' onChange={this.useCurrentDateTime} />
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field inline>
                      <label>Session ID</label>
                      <input value={this.props.sessionId} name="sessionId" onChange={this.handleSessionIDChange} type="number" />
                    </Form.Field>

                    <Form.Field inline>
                      <label>Transaction ID</label>
                      <Input value={this.props.transactionRef} name="transactionRef" onChange={this.handleChange} />
                    </Form.Field>

                    <Form.Field inline>
                      <label>EGM Asset No</label>
                      <Input value={this.props.assetNo} name="assetNo" onChange={this.handleChange} />
                    </Form.Field>

                    { this.props.logIn.optionShowPlayerID &&
                    <Form.Field inline>
                      <label>Customer ID</label>
                      <Input value={this.props.playerId} name="playerId" onChange={this.handleChange} />
                    </Form.Field>
                    }
                  </Form.Group>




              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Form>
        )
    }
}

const mapStateToProps = state => {
	return {
    logIn: state.logIn
  };
};

export default connect(mapStateToProps)(CompanyFilters);
