import axios from 'axios';
import { store } from '../index';
import { showSuccessAlert } from '../actions/modals';

const instance = axios.create(
	{
		baseURL: 'https://clientapi-dev.riminienterprise.com/api'
	}
)

instance.interceptors.request.use ((config) => {
	const logIn = JSON.parse(sessionStorage.getItem('login'));
	
	if (logIn && logIn.sessionkey) {
		config = {...config, ...{ headers: {common: {'SessionKey': `${logIn.sessionkey}`} } } }
	} 

	return config;
});

instance.interceptors.response.use(
	function (response) {
    	return response;
  	}, function (error) {
		const logIn = JSON.parse(sessionStorage.getItem('login'));
		
		if (logIn) {
			if (error.response) {
				if (error.response.status === 401) {
					sessionStorage.removeItem('login');
					window.location = '/?expired=1'
				} else {
					if (error.response.data) {
						const message = typeof error.response.data === "string" ? error.response.data : "There was an error completing your request";
						store.dispatch(showSuccessAlert(message, "Error"));
					} else {
						store.dispatch(showSuccessAlert("There was an error completing your request!", "Error"));
					}
				}
			} else {
				store.dispatch(showSuccessAlert("There was an error completing your request!", "Error"));
			}
		}

		return Promise.reject(error);
	}
);

export default instance;