import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import {
  checkForUpdates, emptySessionsSection, fetchSession,
  fetchSessionsFilters, fetchSessionsWithSummary, fetchSessionsExport,
  updateSessionsWithSummary, fetchBlankSummarySessions
} from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import { defaultFromDate } from '../../config/dates';
import SessionDetailsModal from '../modals/SessionDetailsModal';
import CompanyFilters from '../shared/CompanyFilters';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';
import Option from '../systemAdmin/Option';


class SessionsView extends React.Component {

  state = {
    searchParams: {
      startDate: moment(defaultFromDate).toISOString(),
      endDate: moment().toISOString(),
      sessionId: null,
      transactionRef: null,
      assetNo: null,
      playerId: null,
      details: {},
      zeroBetSessions: false
    },
    loading: false,
    useCurrentDateTime: true,
    modalOpen: false,
    modalTitle: "",
    showSummary: true,
  };

  handleClose = () => this.setState({ modalOpen: false })

  handleSessionSelection = term => {
    this.setState({loading: true}, () => {
      this.props.fetchSession(term.sessionId).then(() => {
        this.setState({
          modalOpen: true,
          modalTitle: `Session Details ${term.sessionId}`,
          loading: false
        });
      });
    });
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      this.props.fetchBlankSummarySessions(this.props.filters.filterSelected.operator)
    }

    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.handleRefresh();
    }
  }

  setUseCurrentDateTime = () => {
    this.setState({useCurrentDateTime: !this.state.useCurrentDateTime})
  }

  handleChange = (key, value, callback = () => {}) => {
    let params = this.state.searchParams;
    params[key]=value;
    this.setState({searchParams: params}, callback);

  }

  /**
   * Find all controls in the grid and populate the details object with their codes and values
   */
  getDetails = () => {
    const details = {};
    const data = this.props.sessionsByCompany.summary.data;

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.type === "control") {
          details[cell.data.code] = Option.getValue(cell.data);
        }
      })
    })

    return details;
  }

  handleOptionChange = (option, value) => {    
    const { summary } = this.props.sessionsByCompany;
    const data = [...summary.data];

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.data.code === option.code) {
          data[rowIndex].cellData[cellIndex].data = {...option}
        }
      })
    })

    this.props.updateSessionsWithSummary({ summary: {...summary, data } })
  }

  handleRefresh = () => {
    if (this.state.useCurrentDateTime) {
      this.handleChange('endDate', moment().toISOString(), () => this.loadSessions());
    } else {
      this.loadSessions();
    }
  }

  loadSessions = () => {
    const searchParams = this.state.searchParams
    const { operator, company, subcompany, site } = this.props.filters.filterSelected
    const { filterSelected, filterData } = this.props.filters

    this.setState({loading: true  }, () => {
      this.props.fetchSessionsWithSummary(
        searchParams.startDate,
        searchParams.endDate,
        operator,
        company.id,
        subcompany.id,
        site.id,
        searchParams.sessionId,
        searchParams.assetNo,
        searchParams.playerId, 
        searchParams.transactionRef,
        searchParams.zeroBetSessions,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
        this.getDetails()
      ).then(() => this.setState({loading: false}))
      .catch(() => this.setState({loading: false}))
    });
  }

  loadExport = () => {
    const searchParams = this.state.searchParams
    const { operator, company, subcompany, site } = this.props.filters.filterSelected
    const { filterSelected, filterData } = this.props.filters

    fetchSessionsExport(
      searchParams.startDate,
      searchParams.endDate,
      operator,
      company.id,
      subcompany.id,
      site.id,
      searchParams.sessionId,
      searchParams.assetNo,
      searchParams.playerId, 
      searchParams.transactionRef,
      searchParams.zeroBetSessions,
      prepareFilter(filterSelected.regions, filterData.regions),
      prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
      prepareFilter(filterSelected.game, filterData.game),
      prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
      this.getDetails()
    )
  }

  render() {

    const showSummary = this.props.filters.filterSelected.operator && this.state.showSummary;

    return (
      <React.Fragment>
        <Grid className="height-100 gs-flex-container-vertical">
          <Grid.Row>
            <Grid.Column>
              <div className='flex-vertical mt-1'>
                <div className='flex-shrink'>
                  <div>
                    <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                      <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                    </Button>
                  </div>
                  <div>
                    <DownloadButton disabled={!this.props.filters.hasSelection} onClick={this.loadExport} />
                  </div>
                </div>
                <div className='flex-stretch ml-1'>
                  <CompanyFilters
                    hasSelection={this.props.filters.hasSelection}
                    {...this.state.searchParams}
                    company={this.props.filters.filterSelected.company}
                    subcompany={this.props.filters.filterSelected.subcompany}
                    site={this.props.filters.filterSelected.site}
                    useCurrentDateTime={this.state.useCurrentDateTime}
                    setUseCurrentDateTime={this.setUseCurrentDateTime}
                    onChange={this.handleChange}
                    refresh={this.handleRefresh}
                    refreshDisabled={!this.props.filters.hasSelection || this.state.loading}
                    loading={this.state.loading}
                    hideTransactionFilter={true}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="flex-stretch">
            { showSummary && 
              <Grid.Column computer="6" largeScreen="4" widescreen="3" className="gs-flex-container-vertical">
                  <DataGridRequest
                    loading={this.state.loading}
                    headings={this.props.sessionsByCompany.summary.headerDef}
                    data={this.props.sessionsByCompany.summary.data}
                    rowDef={this.props.sessionsByCompany.summary.rowDef}
                    hideOverflow
                    disableSort={true}
                    disableActiveRow={true}
                    onOptionChange={this.handleOptionChange}
                  />
              </Grid.Column>
            }
            <Grid.Column computer={ showSummary ? "10" : "16" } largeScreen={ showSummary ? "12" : "16" } widescreen={ showSummary ? "13" : "16" } className="gs-flex-container-vertical">
              <DataGridRequest loading={this.state.loading}
                headings={this.props.sessionsByCompany.grid.headerDef}
                rowDef={this.props.sessionsByCompany.grid.rowDef}
                data={this.props.sessionsByCompany.grid.data}
                onRowClick={this.handleSessionSelection}
                fullHeight
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <SessionDetailsModal
          modalOpen={this.state.modalOpen}
          handleClose={this.handleClose}
          modalTitle={this.state.modalTitle}
          session={this.props.session}
        />
      </React.Fragment>
    );
  }
};


const mapStateToProps = state => {
	return {
    filters: state.sessionsFilters,
    sessionsByCompany: state.sessionsByCompany,
    session: state.session,
    searchParams: state.searchParams
  };
};

export default connect(mapStateToProps, {
  fetchSessionsWithSummary,
  updateSessionsWithSummary,
  fetchSession,
  fetchBlankSummarySessions,
  fetchSessionsFilters,
  emptySessionsSection
})(SessionsView);
