import moment from 'moment';
import jsonPlaceholder from '../api/jsonPlaceholder';
import { newFile } from '../components/helpers/downloads';
import { UIFileFormat } from '../config/dates';
import { emptyGrid } from './datagrid';
import { prepareDetails } from './settings';


export const fetchInstallations = (OperatorId, SiteId, ShowHistory, OperatorRegions, Manufacturers, GameNames, Categories) => async dispatch => {
	const params = {
		OperatorId,
		SiteId,
		ShowHistory,
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		Details: "",
	}
	const response = await jsonPlaceholder.put(`organisation/filterbymachine2`, params)
	dispatch({type: 'FETCH_INSTALLATIONS', payload: response.data});
};

export const fetchTransaction = (id) => async dispatch => {
	const transactions = await jsonPlaceholder.get(`Transactions/Details/${id}`).catch(error => {
		dispatch({type: 'FETCH_TRANSACTION', payload: []});
	});
	dispatch({type: 'FETCH_TRANSACTION', payload: transactions.data});
};

export const fetchTransactions = (
	installationId,
	startDate,
	endDate,
	includeEvents=true,
	etfIn=true,
	etfOut=true,
	suspiciousOpen=true,
	suspiciousVoid=true,
	suspiciousAuth=true,
	cancelled=true,
	partial=true
) => async dispatch => {

		if(!startDate){
			startDate = moment().format("DD MMM YYYY [00:00]");
		}
		if(!endDate){
			endDate = moment().format("DD MMM YYYY HH:mm");
		}
		const transactions = await jsonPlaceholder.get(`Transactions/installationsUI2/${startDate},${endDate},${installationId},${includeEvents},${etfIn},${etfOut},${suspiciousOpen},${suspiciousVoid},${suspiciousAuth},${cancelled},${partial}`)

		let data = transactions.data;
		data.startDate = startDate;
		data.endDate = endDate;
		data.includeEvents = includeEvents;
		data.installationId = installationId;
		dispatch({type: 'FETCH_TRANSACTIONS', payload: data});
	
};

export const fetchInstallTransactionsWithSummary = (
	installationId,
	startDate,
	endDate,
	includeEvents,
	details,
) => async dispatch => {
	const params = {
		InstallationId: Math.trunc(installationId),
		StartDate: startDate.toISOString(),
		EndDate: endDate.toISOString(),
		IncludeEvents: includeEvents,
		Details: prepareDetails(details)
	}

	const response = await jsonPlaceholder.put(`Transactions/installationsUI3`, params)
	const data = {...response.data, startDate, endDate, includeEvents, installationId}
	dispatch({type: 'FETCH_TRANSACTIONS', payload: data});
};

export const fetchInstallTransactionsExport = async (
	installationId,
	startDate,
	endDate,
	includeEvents,
	details,
) => {
	const params = {
		InstallationId: Math.trunc(installationId),
		StartDate: startDate.toISOString(),
		EndDate: endDate.toISOString(),
		IncludeEvents: includeEvents,
		Details: prepareDetails(details)
	}

	const fileName = `installation-transactions-${moment().format(UIFileFormat)}.csv`;
	const response = await jsonPlaceholder.put(`Transactions/installations/export`, params)
	const contentType = response.headers["content-type"] || "text/csv";
	newFile(response.data, fileName, contentType)
};

export const clearInstallSessions = () => async dispatch => {
	dispatch({type: 'FETCH_SESSIONS', payload: { grid: { data: [], headerDef: [], rowDef: [] } } });
}

export const clearInstallTransactions = () => async dispatch => {
	dispatch({type: 'FETCH_TRANSACTIONS', payload: { grid: { data: [], headerDef: [], rowDef: [] } } });
}

export const fetchInstallSessionsWithSummary = (installationId, startDate, endDate, ZeroBetSessions, details) => async dispatch => {
	const params = {
		InstallationId: Math.trunc(installationId),
		StartDate: startDate.toISOString(),
		EndDate: endDate.toISOString(),
		ZeroBetSessions,
		Details: prepareDetails(details)
	}

	const response = await jsonPlaceholder.put(`Sessions/installationsUI2/`, params);
	const data = {...response.data, startDate, endDate, installationId}
	dispatch({type: 'FETCH_SESSIONS', payload: data});
}

export const fetchInstallSessionsExport = async (installationId, startDate, endDate, ZeroBetSessions, details) => {
	
	const params = {
		InstallationId: Math.trunc(installationId),
		StartDate: startDate.toISOString(),
		EndDate: endDate.toISOString(),
		ZeroBetSessions,
		Details: prepareDetails(details)
	}

	const response = await jsonPlaceholder.put(`sessions/installations/export`, params);
	const fileName = `installation-sessions-${moment().format(UIFileFormat)}.csv`;
	const contentType = response.headers["content-type"] || "text/csv";
	newFile(response.data, fileName, contentType)}

export const fetchSessions = (installationId, startDate, endDate, eftSessions=true, cashSessions=true) => async dispatch => {
	if(!installationId){
		dispatch({type: 'FETCH_SESSIONS', payload: []});
	}else{
		if(!startDate){
			startDate = moment().format("DD MMM YYYY [00:00]");
		}
		if(!endDate){
			endDate = moment().format("DD MMM YYYY HH:mm");
		}
		const sessions = await jsonPlaceholder.get(`Sessions/installationsUI/${startDate},${endDate},${installationId},${eftSessions },${cashSessions}`).catch(error => {
			dispatch({type: 'FETCH_SESSIONS', payload: []});
		});
		let data = sessions.data;
		data.startDate = startDate;
		data.endDate = endDate;
		data.installationId = installationId;
		dispatch({type: 'FETCH_SESSIONS', payload: data});
	}
};



export const fetchSessionsWithSummary = (installationId, startDate, endDate, eftSessions=true, cashSessions=true) => async dispatch => {
	if(!installationId){
		dispatch({type: 'FETCH_SESSIONS', payload: []});
	}else{
		if(!startDate){
			startDate = moment().format("DD MMM YYYY [00:00]");
		}
		if(!endDate){
			endDate = moment().format("DD MMM YYYY HH:mm");
		}
		const sessions = await jsonPlaceholder.get(`Sessions/installationsUI/${startDate},${endDate},${installationId},${eftSessions },${cashSessions}`).catch(error => {
			dispatch({type: 'FETCH_SESSIONS', payload: []});
		});
		let data = sessions.data;
		data.startDate = startDate;
		data.endDate = endDate;
		data.installationId = installationId;
		dispatch({type: 'FETCH_SESSIONS', payload: data});
	}
};


export const fetchSession = (id) => async dispatch => {
	await jsonPlaceholder.get(`Sessions/Details/${id}`).then(session => {
		dispatch({type: 'FETCH_SESSION', payload: session.data});
	}).catch(error => {
		dispatch({type: 'FETCH_SESSION', payload: {error: true} });
	})
};

export const fetchAsset = async (id, positionId) => {
	const response = await jsonPlaceholder.get(`Organisation/asset2/${id},${positionId}`);
	return response.data;
};


export const setAsset = async (PositionId, AssetNo, Details, Complete) => {

	const payload = {
		Complete,
		PositionId,
		AssetNo,
		Details: prepareDetails(Details)
	}
	
	const response = await jsonPlaceholder.put(`Installation/installposition`, payload);
	return response.data;
}

export const removeAsset = async (installationId) => {
	const response = await jsonPlaceholder.delete(`Installation/${installationId}`);
	return response.data;
};

export const setEft = (installationId, active) => async dispatch => {
	const eft = (active)?'disableEFT':'enableEFT';
	await jsonPlaceholder.put(`Installation/${eft}/${installationId}`).catch(error => {
		dispatch({type: 'SET_EFT', payload: "ERROR"});
	});
	dispatch({type: 'SET_EFT', payload: "OK"});
}

export const setAutorise = (installationId) => async dispatch => {
	await jsonPlaceholder.put(`Installation/authorise/${installationId}`).catch(error => {
		dispatch({type: 'SET_AUTHORISE', payload: "ERROR"});
	});
	dispatch({type: 'SET_AUTHORISE', payload: "OK"});
}

export const emptyInstallationsSection = (clearFilters = true) => async dispatch => {
	dispatch({type: 'FETCH_INSTALLATIONS', payload: emptyGrid });
	dispatch({type: 'FETCH_TRANSACTIONS', payload: emptyGrid});
	dispatch({type: 'FETCH_SESSIONS', payload: emptyGrid});
	dispatch({type: 'SET_ACTIVE_INSTALLATION', payload: {}});
	if (clearFilters) dispatch({type: 'FETCH_INSTALLATIONS_FILTERS', payload: []});

}
export const addPositions = (siteId, numPositions) => async dispatch => {
	const asset = await jsonPlaceholder.put(`Organisation/AddPositions/${siteId},${numPositions}`).catch(error => {
		dispatch({type: 'ADD_POSITIONS', payload: "ERROR"});
	});
	if(!asset){
		dispatch({type: 'ADD_POSITIONS', payload: "ERROR"});
	}else{
		dispatch({type: 'ADD_POSITIONS', payload: "OK"});
	}
}

export const setActiveInstallation = (row = {}) => async dispatch => {
	dispatch({type: "SET_ACTIVE_INSTALLATION", payload: row});
}
