import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Grid, Header, Icon, Modal } from 'semantic-ui-react';
import { clearInstallSessions, fetchBlankSummary, fetchInstallSessionsExport, fetchInstallSessionsWithSummary, fetchSession } from '../../actions';
import { defaultFromDate, UIDateFormat, UITimeFormat } from '../../config/dates';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';
import SessionDetails from '../shared/SessionDetails';
import Option from '../systemAdmin/Option';

class Sessions extends React.Component {
  state = {
    fromDate: moment(defaultFromDate),
    toDate: moment(),
    currentDate: true,
    session: [],
    sessions: [],
    modalOpen: false,
    zeroBetSessions: false,
    modalTitle: "",
    searchParams:{
      eftSessions: true,
      cashSessions: true,
    },
    loading: false,
    accordionOpen: false,
    summaryOptions: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    showSummary: false,
    details: {}
  };

  handleChange = (event, {name, value}) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  handleDateChange = (event, {name, value}) => {
    const date = moment(value, UIDateFormat);
    date.set({
      hour: this.state[name].get('hour'),
      minute: this.state[name].get('minute'),
      second: this.state[name].get('second'),
    })
    
    this.setState({ [name]: date })
  }

  handleTimeChange = (event, {name, value}) => {
    const date = this.state[name]
    const time = moment(`${date.format(UIDateFormat)} ${value}`, `${UIDateFormat} HH:mm:ss`);
    this.setState({ [name]: time })
  }

  handleCheckboxChange = (key, value) => {

    let params = this.state.searchParams;
    params[key]=value;
    this.setState({searchParams: params});
  }

  componentDidMount() {
    this.updateSessions();
  }

  updateSessions = () => {
    const toDate = this.state.currentDate ? moment() : this.state.toDate;
    this.setState({ toDate }, () => this.handleRefresh());
  }

  loadExport = () => {
    if (this.state.currentDate) {
      this.setState({ toDate: moment() }, () => {
        this.getExport();
      })
    } else {
      this.getExport();
    }
  }

  getExport = () => {
    
    fetchInstallSessionsExport(
      this.props.installation,
      this.state.fromDate,
      this.state.toDate,
      this.state.zeroBetSessions,
      this.state.details
    )
  }

  handleRefresh = () => {
    if (!this.props.installation) {
      this.props.clearInstallSessions();
    } else {
      this.setState({ loading: true }, () => {
        
        this.props.fetchInstallSessionsWithSummary(
          this.props.installation,
          this.state.fromDate,
          this.state.toDate,
          this.state.zeroBetSessions,
          this.state.details
        ).then(() => {
          this.setState({loading: false, summaryOptions: { grid: this.props.sessions.summary } }, () => this.setDetails());
        })
        .catch(() => {
          this.props.clearInstallSessions().then(() => this.setState({loading: false}));
        });
      });
    }
  }

  handleClose = () => this.setState({ modalOpen: false })

  handleSessionSelection = term => {
    this.setState({loading: true }, () => {
      this.props.fetchSession(term.sessionId).then(() => {
        this.setState({
          loading: false,
          modalOpen: true,
          modalTitle: `Session Details : ${term.sessionId}`
        });
      });
    });
  }

  /**
   * Find all controls in the grid and populate the details object with their codes and values
   */
  setDetails = () => {
    const details = {};
    const data = this.state.summaryOptions.grid.data;

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.type === "control") {
          details[cell.data.code] = Option.getValue(cell.data);
        }
      })
    })

    this.setState({ details })
  }


  handleOptionChange = (option, value) => {
    const data = [...this.state.summaryOptions.grid.data];

    data.forEach((row, rowIndex) => {
      row.cellData.forEach((cell, cellIndex) => {
        if (cell.data.code === option.code) {
          data[rowIndex].cellData[cellIndex].data = option
        }
      })
    })
    
    const grid = {...this.state.summaryOptions.grid, data }
    this.setState({ summaryOptions: {...this.state.summaryOptions, grid} }, () => this.setDetails())
  }  

  searchSegment(){
    return (
      <form onSubmit={(e)=>{e.preventDefault()}} className="ui form">
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                  <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                </Button>

                <span className="mr-1"><DownloadButton disabled={!this.props.installation} onClick={this.loadExport} /></span>

                From:
                <DateInput
                  className="ui input ml-1"
                  style={{width: '9em'}}
                  name="fromDate"
                  placeholder="Date"
                  value={this.state.fromDate.format(UIDateFormat)}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  animation="fade"
                  popupPosition="bottom center"
                  closable
                  autoComplete="off"
                />
                <TimeInput
                  className="ui input"
                  style={{width: '6em'}}
                  name="fromDate"
                  placeholder="Time"
                  value={this.state.fromDate.format(UITimeFormat)}
                  iconPosition="left"
                  onChange={this.handleTimeChange}
                  animation="fade"
                  popupPosition="bottom center"
                  closable
                  autoComplete="off"
                />

                { !this.state.currentDate && <span name="toDateTime">
                  <label className="ml-1 ui">To:</label>
                  <DateInput
                    className="ui input ml-1"
                    style={{width: '9em'}}
                    name="toDate"
                    placeholder="Date"
                    value={this.state.toDate.format(UIDateFormat)}
                    iconPosition="left"
                    onChange={this.handleDateChange}
                    animation="fade"
                    popupPosition="bottom center"
                    closable
                    autoComplete="off"
                  />
                  <TimeInput
                    className="ui input"
                    style={{width: '6em'}}
                    name="toDate"
                    placeholder="Time"
                    value={this.state.toDate.format(UITimeFormat)}
                    iconPosition="left"
                    onChange={this.handleTimeChange}
                    animation="fade"
                    popupPosition="bottom center"
                    closable
                    autoComplete="off"
                  />
                </span> }

                <Checkbox className="ml-1" name="currentDate" label="Current Date/Time" checked={this.state.currentDate} onChange={() => this.setState( { currentDate: !this.state.currentDate } )} />
                <Checkbox name="zeroBetSessions" label='Zero Bet Sessions Only' checked={this.state.zeroBetSessions} onChange={() => this.setState({ zeroBetSessions: !this.state.zeroBetSessions})} />

                {this.props.installation && <Button disabled={this.state.loading} onClick={this.updateSessions} className="ml-1" positive>Refresh</Button> }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </form>
    );
  }

  componentDidUpdate(prevProps){
    // Check for a change in the transaction list
    if (this.props.installation !== prevProps.installation) this.updateSessions();
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator && this.props.filters.filterSelected.operator != null) {
      fetchBlankSummary(this.props.filters.filterSelected.operator).then(response => {
        this.setState({summaryOptions: { grid: response }}, () => this.setDetails())
      })
    }
    return true
  }

  render() {
    var headings, rowDef, data = [];
    if(this.props.sessions.grid){
      headings = this.props.sessions.grid.headerDef;
      rowDef = this.props.sessions.grid.rowDef;
      data = this.props.sessions.grid.data;
    }

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row>
          <Grid.Column>
        {this.searchSegment()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
          { (this.props.filters.filterSelected.operator && this.state.showSummary) && 
            <Grid.Column computer="6" largeScreen="4" widescreen="3" className="gs-flex-container-vertical">
              <DataGridRequest
                loading={this.state.loading}
                headings={this.state.summaryOptions.grid.headerDef}
                data={this.state.summaryOptions.grid.data}
                rowDef={this.state.summaryOptions.grid.rowDef}
                hideOverflow
                disableSort={true}
                disableActiveRow={true}
                onOptionChange={this.handleOptionChange}
              />
            </Grid.Column>
          }
          <Grid.Column computer={ this.state.showSummary ? "10" : "16" } largeScreen={ this.state.showSummary ? "12" : "16" } widescreen={ this.state.showSummary ? "13" : "16" } className="gs-flex-container-vertical">
            <DataGridRequest
              loading={this.state.loading}
              headings={headings}
              data={data}
              rowDef={rowDef}
              onRowClick={this.handleSessionSelection}
              fullHeight
            />
            <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
              size='fullscreen'
              closeIcon
              className="h-80"
            >
            <Header content={this.state.modalTitle} />
            <Modal.Content className="h-100">
              <SessionDetails key="details" sessionDetails={this.props.session} />
            </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      );
  }
}

const mapStateToProps = state => {
	return {
    session: state.session,
    sessions: state.sessions,
    installation: state.activeInstallation.installationId,
    filters: state.installationsFilters    
  };
};

export default connect(mapStateToProps, {
  fetchSession,
  fetchInstallSessionsWithSummary,
  clearInstallSessions,
})(Sessions);
