import React from 'react';
import { connect } from 'react-redux';
import { Grid, Message } from 'semantic-ui-react';
import DataGridRequest from '../shared/DataGridRequest';

class SessionDetails extends React.Component {
  render() {
    if(this.props.sessionDetails.assetNo){
      return(
        <Grid className="height-100 flex-container">
        { (this.props.sessionDetails.isSuspicious && this.props.sessionDetails.suspiciousReason) &&
          <Grid.Row>
          <Grid.Column width={16}>
          <Message negative>{this.props.sessionDetails.suspiciousReason}</Message>
          </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width="16">
            <b>{this.props.sessionDetails.site}</b> - {this.props.sessionDetails.gameName} 
            { this.props.logIn.optionShowPlayerID && <span className="ml-2"><b>CUSTOMER ID:</b> {this.props.sessionDetails.playerID || "N/A" }</span> }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width="16">
            <b>SESSION START:</b> {this.props.sessionDetails.startDate}
            <span className="ml-2"><b>SESSION END:</b> {this.props.sessionDetails.endDate}</span>
            <span className="ml-2"><b>DURATION (HH:MM)</b> {this.props.sessionDetails.duration || "N/A"}</span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch-vertical">
        <Grid.Column width={5} className="flex-container">
          <DataGridRequest
            stretched
            headings={this.props.sessionDetails.summarysGrid.headerDef}
            rowDef={this.props.sessionDetails.summarysGrid.rowDef}
            data={this.props.sessionDetails.summarysGrid.data} onRowClick={function(){}}
            fullHeight
          />
        </Grid.Column>
        <Grid.Column width={11} className="flex-container">
          <DataGridRequest styleInf={{height: 200}}
          headings={this.props.sessionDetails.eventsGrid.headerDef}
          rowDef={this.props.sessionDetails.eventsGrid.rowDef}
          data={this.props.sessionDetails.eventsGrid.data} onRowClick={function(){}}
          />
          <br/>
          <DataGridRequest
          headings={this.props.sessionDetails.metersGrid.headerDef}
          rowDef={this.props.sessionDetails.metersGrid.rowDef}
          noResults="Session open, meter data not available."
          data={this.props.sessionDetails.metersGrid.data} onRowClick={function(){}}
          fullHeight
          />
        </Grid.Column>
        </Grid.Row>
        </Grid>
      );
    }else{
      return (
        <Message negative>There was an error loading the session details.</Message>
      )
    }
  }
}
const mapStateToProps = state => {
	return {
    logIn: state.logIn
  };
};
export default connect(mapStateToProps, null)(SessionDetails);
