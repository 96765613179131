import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon } from 'semantic-ui-react';
import {
  checkForUpdates, emptyCollectionsSection,
  fetchBlankCollectionsSummaryCollections, fetchCollectionByRef, fetchCollections, fetchCollectionsExport,
  fetchCollectionsFilters, fetchTransaction
} from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import CollectionsFilters from '../collections/CollectionsFilters';
import CollectionsFiltersOther from '../collections/CollectionsFiltersOther';
import CollectionsDetailsModal from '../modals/CollectionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';

class CollectionView extends React.Component {

  state = {
    searchParams: {
      startDate: moment().subtract(7, 'day').startOf('day'),
      endDate: moment(),
      companyId: null,
      subCompanyId: null,
      siteId: null,
      assetNo: '',
      collectionId: '',
      periodType: 2,
      lastxPeriod: 1
    },
    companyInfo: {
      company: '',
      subCompany: '',
      site: ''
    },
    loading: false,
    modalOpen: false,
    modalTitle: "",
    showEvents: false,
    useCurrentDateTime: true,
    showSummary: true,
    selectedCollection: null,
  };

  handleChange = (key, value) => {
    var changeValues = {[key] : value};
    let params = {...this.state.searchParams, ...{[key] : value}}
    if(key==='periodType'){
      switch (value) {
        case 0:
          //Month default
          changeValues = {[key] : value, lastxPeriod: 1};
          break;
        default:
          //Week default
          changeValues = {[key] : value, lastxPeriod: 20};
          break;
      }
      params = {...this.state.searchParams, ...changeValues}
    }

    this.setState({searchParams: params});
  }

  updateCollections = () => {
    const endDate = this.state.useCurrentDateTime ? moment() : this.state.searchParams.endDate;
    this.setState( { searchParams: {...this.state.searchParams, endDate }}, () => this.handleRefresh());
  }

  handleRefresh = () => {
    this.setState({loading: true }, () => {
      const searchParams = this.state.searchParams
      const filterSelected = this.props.filters.filterSelected;
      const filterData = this.props.filters.filterData;
      const startDate = moment(searchParams.startDate).toISOString()
      const endDate = moment(searchParams.endDate).toISOString()
     
      this.props.fetchCollections(
        filterSelected.operator, 
        filterSelected.company.id,
        filterSelected.subcompany.id,
        filterSelected.site.id, 
        -1,
        startDate, 
        endDate, 
        searchParams.collectionId,
        searchParams.assetNo,
        searchParams.periodType,
        searchParams.lastxPeriod,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
      )
      .then(
        () => this.setState({ loading: false }),
        () => this.setState({ loading: false })
      )
    });
  }

  loadExport = () => {
      const searchParams = this.state.searchParams
      const filterSelected = this.props.filters.filterSelected;
      const filterData = this.props.filters.filterData;
      const startDate = moment(searchParams.startDate).toISOString()
      const endDate = moment(searchParams.endDate).toISOString()
      
      fetchCollectionsExport(
        filterSelected.operator, 
        filterSelected.company.id,
        filterSelected.subcompany.id,
        filterSelected.site.id, 
        -1,
        startDate, 
        endDate, 
        searchParams.collectionId,
        searchParams.assetNo,
        searchParams.periodType,
        searchParams.lastxPeriod,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
      ) 
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      this.props.fetchBlankCollectionsSummaryCollections(this.props.filters.filterSelected.operator);
    }

    if (checkForUpdates(prevProps.filters, this.props.filters)) {
      this.updateCollections();
    }
  }

  handleRowClick = (row) => {
    this.setState({loading: true, activeRow: row}, () => {
      this.props.fetchCollectionByRef(row.detailRef, this.state.showEvents).then(selectedCollection => {
        this.setState({modalOpen: true, selectedCollection, modalTitle: `Collection Details ${selectedCollection.collectionId}`, loading: false})
      });
    });
  }

  handleShowEventsChange = async () => {
    this.setState({showEvents: !this.state.showEvents }, () => {
      this.handleRowClick(this.state.activeRow)
    });
  }

  handleUseCurrentDateTime = () => {
    let newState = {useCurrentDateTime: !this.state.useCurrentDateTime};

      if (this.state.useCurrentDateTime) {
        newState = {...newState, searchParams: {...this.state.searchParams, ...{endDate: moment()} } }
      }

    this.setState(newState)
  }

  render() {
    const showSummary = this.props.filters.filterSelected.operator && this.state.showSummary;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row columns='equal'>
          <Grid.Column width="8">
              <div className='flex-vertical'>
                <div className='flex-shrink'>
                  <div>
                    <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
                      <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
                    </Button>
                  </div>
                  <DownloadButton disabled={!this.props.filters.hasSelection} onClick={this.loadExport} />
                </div>
                <div className='flex-stretch ml-1'>
                <CollectionsFilters
                  {...this.state.searchParams}
                  useCurrentDateTime={this.state.useCurrentDateTime}
                  onChangeUseCurrentDateTime={this.handleUseCurrentDateTime}
                  onChange={this.handleChange}
                />
              </div>
              <div className='flex-stretch ml-1'>
                <CollectionsFiltersOther
                  {...this.state.searchParams}
                  useCurrentDateTime={this.state.useCurrentDateTime}
                  onChangeUseCurrentDateTime={this.handleUseCurrentDateTime}
                  onChange={this.handleChange}
                />
                <Button size='small' onClick={this.updateCollections} loading={this.state.loading} disabled={!this.props.filters.hasSelection || this.state.loading} positive>Refresh</Button>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
            { showSummary && 
              <Grid.Column computer="6" largeScreen="6" widescreen="4" className="gs-flex-container-vertical">
                  <DataGridRequest
                    loading={this.state.loading}
                    headings={this.props.collections.summary.headerDef}
                    data={this.props.collections.summary.data}
                    rowDef={this.props.collections.summary.rowDef}
                    hideOverflow
                    disableSort={true}
                    disableActiveRow={true}
                    onOptionChange={this.handleOptionChange}
                  />
              </Grid.Column>
            }
            <Grid.Column computer={ showSummary ? "9" : "16" } largeScreen={ showSummary ? "10" : "16" } widescreen={ showSummary ? "12" : "16" } className="gs-flex-container-vertical">
            <CollectionsDetailsModal
              modalOpen={this.state.modalOpen}
              modalData={this.state.selectedCollection}
              modalTitle={this.state.modalTitle}
              handleClose={() => this.setState({modalOpen: false})}
              onChange={this.handleShowEventsChange}
              showEvents={this.state.showEvents}
              loading={this.state.loading}
              onTransactionClose={() => this.handleRowClick(this.state.activeRow)}
            />
            <DataGridRequest
              loading={this.state.loading}
              headings={this.props.collections.grid.headerDef}
              rowDef={this.props.collections.grid.rowDef}
              data={this.props.collections.grid.data} onRowClick={this.handleRowClick}
              stretched
              fullHeight
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = state => {
	return {
    filters: state.collectionsFilters,
    collections: state.collections
  };
};

export default connect(mapStateToProps, {
  fetchCollections,
  fetchCollectionByRef,
  fetchTransaction,
  fetchBlankCollectionsSummaryCollections,
  fetchCollectionsFilters,
  emptyCollectionsSection
})(CollectionView);