import moment from 'moment';
import jsonPlaceholder from '../api/jsonPlaceholder';
import { APIDateTimeFormat, UIFileFormat } from '../config/dates';
import { emptyGrid } from './datagrid';
import { prepareDetails } from './settings';
import { newFile } from '../components/helpers/downloads';

export const fetchTransactionsWithSummary =
 (
	StartDate,
	EndDate,
	OperatorId,
	CompanyId,
	SubCompanyId,
	SiteId,
	IncludeEvents,
	SessionId,
	TransactionRef,
	AssetNo,
	PlayerId,
	OperatorRegions,
	Manufacturers,
	GameNames,
	Categories,
	Details
) => async dispatch => {
	const params = { 
		StartDate, 
		EndDate, 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		IncludeEvents: IncludeEvents || false,
		SessionId: parseInt(SessionId) || -1,
		TransactionRef: TransactionRef || "#",
		AssetNo: AssetNo || "#", 
		PlayerId: PlayerId || "#", 
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		Details: prepareDetails(Details)
	}

	const response = await jsonPlaceholder.put(`transactions/filterbymachine2/`, params)
	dispatch({type: 'FETCH_TRANSACTIONS_BY_COMPANY', payload: response.data});
}

export const updateTransactionsWithSummary = (data) => async dispatch => {
	dispatch({type: 'FETCH_TRANSACTIONS_BY_COMPANY', payload: data});
}

export const fetchTransactionsExport =
async (
	StartDate,
	EndDate,
	OperatorId,
	CompanyId,
	SubCompanyId,
	SiteId,
	IncludeEvents,
	SessionId,
	TransactionRef,
	AssetNo,
	PlayerId,
	OperatorRegions,
	Manufacturers,
	GameNames,
	Categories,
	Details
) => {
	const params = { 
		StartDate, 
		EndDate, 
		OperatorId, 
		CompanyId: CompanyId || -1, 
		SubCompanyId: SubCompanyId || -1,
		SiteId: SiteId || -1, 
		IncludeEvents: IncludeEvents || false,
		SessionId: parseInt(SessionId) || -1,
		TransactionRef: TransactionRef || "#",
		AssetNo: AssetNo || "#", 
		PlayerId: PlayerId || "#", 
		OperatorRegions,
		Manufacturers,
		GameNames,
		Categories,
		Details: prepareDetails(Details)
	}

	const fileName = `transactions-${moment().format(UIFileFormat)}.csv`;
	const response = await jsonPlaceholder.put(`Transactions/export/`, params)
	const contentType = response.headers["content-type"] || "text/csv";
	newFile(response.data, fileName, contentType)}



export const setTransactionAdjust = (transactionId, newValue) => async dispatch => {
	await jsonPlaceholder.put(`Transactions/Adjust/${transactionId},${newValue}`);
};


export const setTransactionAuthorise = (transactionId) => async dispatch => {
	await jsonPlaceholder.put(`Transactions/Authorise/${transactionId}`);
};


export const setTransactionVoid = (transactionId) => async dispatch => {
	await jsonPlaceholder.put(`Transactions/Void/${transactionId}`);
};

export const emptyTransactionsSection = (clearFilters = true) => async dispatch => {
	dispatch({type: 'FETCH_TRANSACTIONS_BY_COMPANY', payload: emptyGrid });
	if (clearFilters) dispatch({type: 'FETCH_TRANSACTIONS_FILTERS', payload: {}});
}

export const fetchTransBlankSummary = async (operatorId) => {
	const response = await jsonPlaceholder.get(`/transactions/blanksummary/${operatorId}`);
	return response.data
}

export const fetchTransBlankSummaryTransactions = (operatorId) => async dispatch => {
	fetchTransBlankSummary(operatorId).then(data => dispatch({type: 'FETCH_TRANSACTIONS_BY_COMPANY', payload: { summary: data } }));
}

export const setTransactionReverseVoid = (transactionId) => async dispatch => {
	await jsonPlaceholder.put(`Transactions/ReverseVoid/${transactionId}`);
};