

import React from 'react';
import { Header, Modal } from 'semantic-ui-react'
import SessionDetails from '../shared/SessionDetails'

class SessionDetailsModal extends React.Component {

  render() {
    return (
        <Modal
            open={this.props.modalOpen}
            onClose={this.props.handleClose}
            size='fullscreen'
            closeIcon
            className="h-80"
        >
            <Header content={this.props.modalTitle} />
            <Modal.Content className="h-100">
                <SessionDetails key="details" sessionDetails={this.props.session} />
            </Modal.Content>
        </Modal>
    )
  }
}

export default SessionDetailsModal;
