import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DateInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Grid, Icon, Input, Select } from 'semantic-ui-react';
import {
  emptyCollectionsSection,
  fetchBlankCollectionsSummary, fetchCollectionByRef, fetchCollections, fetchCollectionsExport,
  fetchCollectionsFilters, fetchTransaction,
  checkForUpdates
} from '../../actions';
import { prepareFilter } from '../../actions/organisationDirectory';
import { UIDateFormat } from '../../config/dates';
import period from '../helpers/collections';
import CollectionDetailsModal from '../modals/CollectionDetailsModal';
import DataGridRequest from '../shared/DataGridRequest';
import DownloadButton from '../shared/DownloadButton';

class Collections extends React.Component {

  state = {
    searchParams: {
      startDate: moment().subtract(7, 'day').startOf('day'),
      endDate: moment(),
      companyId: null,
      subCompanyId: null,
      siteId: null,
      assetNo: '',
      collectionId: '',
      periodType: 2,
      lastxPeriod: 1
    },
    companyInfo: {
      company: '',
      subCompany: '',
      site: ''
    },
    loading: false,
    modalOpen: false,
    selectedCollection: null,
    showEvents: false,
    useCurrentDateTime: true,
    showAllCollections: false,
    showSummary: false,
    collections: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    },
    summaryOptions: {
      grid: {
        headerDef: [],
        rowDef: [],
        data: []
      }
    }
  };

  handleClose = () => this.setState({ modalOpen: false })

  handleChange = (event, { name, value }) => {
    var changeValues = {[name] : value};
    let params = {...this.state.searchParams, ...{[name] : value}}
    if(name==='periodType'){
      switch (value) {
        case 0:
          //Month default
          changeValues = {[name] : value, lastxPeriod: 1};
          break;
        default:
          //Week default
          changeValues = {[name] : value, lastxPeriod: 20};
          break;
      }
      params = {...this.state.searchParams, ...changeValues}
    }

    this.setState({searchParams: params});
  }

  updateCollections = () => {
    const endDate = this.state.useCurrentDateTime ? moment() : this.state.searchParams.endDate;
    this.setState( { searchParams: {...this.state.searchParams, endDate }}, () => this.handleRefresh());
  }

  handleRefresh = () => {
    this.setState(
      {
        loading: true,
        collections: {
          grid: {
            headerDef: [],
            rowDef: [],
            data: []
          }
        }
      },
      () => {
        const searchParams = this.state.searchParams
        const filterSelected = this.props.filters.filterSelected;
        const filterData = this.props.filters.filterData;
        const startDate = moment(searchParams.startDate).toISOString()
        const endDate = moment(searchParams.endDate).toISOString()
  
        if (this.props.installation || this.state.showAllCollections) {
          this.props.fetchCollections(
            filterSelected.operator, 
            -1, //filterSelected.company.id,
            -1, //filterSelected.subcompany.id,
            filterSelected.site.id, 
            this.state.showAllCollections ? -1 : this.props.installation,
            startDate, 
            endDate, 
            searchParams.collectionId,
            searchParams.assetNo,
            searchParams.periodType,
            searchParams.lastxPeriod,
            prepareFilter(filterSelected.regions, filterData.regions),
            prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
            prepareFilter(filterSelected.game, filterData.game),
            prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
          )
          .then(result => {
            this.setState({ loading: false, collections: { grid: result.grid }, summaryOptions: { grid: result.summary } });
          })
          .catch(() => {
            this.setState({ loading: false, collections: { grid: { headerDef: [], rowDef: [], data: [] } } });
          })
        } else {
          this.setState({ loading: false })
        }
      }
    );
  }

  loadExport = () => {
    this.setState({ loading: true }, () => {
      const searchParams = this.state.searchParams
      const filterSelected = this.props.filters.filterSelected;
      const filterData = this.props.filters.filterData;
      const startDate = moment(searchParams.startDate).toISOString()
      const endDate = moment(searchParams.endDate).toISOString()
      
      fetchCollectionsExport(
        filterSelected.operator, 
        -1, //filterSelected.company.id,
        -1, //filterSelected.subcompany.id,
        filterSelected.site.id, 
        this.state.showAllCollections ? -1 : this.props.installation,
        startDate, 
        endDate, 
        searchParams.collectionId,
        searchParams.assetNo,
        searchParams.periodType,
        searchParams.lastxPeriod,
        prepareFilter(filterSelected.regions, filterData.regions),
        prepareFilter(filterSelected.manufacturer, filterData.manufacturer),
        prepareFilter(filterSelected.game, filterData.game),
        prepareFilter(filterSelected.machinetypes, filterData.machinetypes),
      ).then(
        () => this.setState({ loading: false }),
        () => this.setState({ loading: false })
      ) 
    })
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.filterSelected.operator !== prevProps.filters.filterSelected.operator) {
      fetchBlankCollectionsSummary(this.props.filters.filterSelected.operator).then(result => {
        this.setState({ summaryOptions: { grid: result }, collections: { grid : { headerDef: [], rowDef: [], data: [] }}})
      })
    }

    if (checkForUpdates(prevProps.filters, this.props.filters) || this.props.installation !== prevProps.installation) {
      this.updateCollections();
    }
  }

  handleRowClick = (row) => {
    this.setState({loading: true, activeRow: row}, () => {
      this.props.fetchCollectionByRef(row.detailRef, this.state.showEvents).then(selectedCollection => {
        this.setState({modalOpen: true, selectedCollection, loading: false})
      });
    });
  }

  handleShowEventsChange = async () => {
    this.setState({showEvents: !this.state.showEvents }, () => {
      this.handleRowClick(this.state.activeRow)
    });
  }

  handleUseCurrentDateTime = () => {

    let newState = {useCurrentDateTime: !this.state.useCurrentDateTime};

      if (this.state.useCurrentDateTime) {
        newState = {...newState, searchParams: {...this.state.searchParams, ...{endDate: moment()} } }
      }

    this.setState(newState)
  }

  handleDateChange = (event, {name, value}) => {
    this.handleChange(event, { name, value: moment(value, UIDateFormat) })
  } 

  searchSegment = () => {
    const periodType = period.find(({value}) => value === this.state.searchParams.periodType)
    return (
      <form onSubmit={(e)=>{e.preventDefault()}} className="ui form">
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
            <Button color='blue' icon size='mini' disabled={!this.props.filters.filterSelected.operator} onClick={() => this.setState( {showSummary: !this.state.showSummary })}>
              <Icon name="pin" rotated={ !this.state.showSummary ? "clockwise" : null } />
            </Button>
            <span className="mr-1"><DownloadButton disabled={!this.props.installation && !this.state.showAllCollections} onClick={this.loadExport} /></span>
            <label style={{ marginRight: "6px"}}>Period:</label>
            <Select
              value={this.state.searchParams.periodType}
              options={period}
              onChange={this.handleChange}
              style={{ width: "110px", minWidth: "auto" }}
              name='periodType'
            />

            { this.state.searchParams.periodType === 2 ?
              <span className="ml-1">
                From:
                <DateInput
                  name="startDate"
                  placeholder="Date"
                  value={moment(this.state.searchParams.startDate)}
                  iconPosition="left"
                  onChange={this.handleDateChange}
                  animation="fade"
                  popupPosition="bottom center"
                  closable
                  className="ui input"
                  style={{width: '9em'}}
                  autoComplete="off"
                  dateFormat= {UIDateFormat}
                />
              </span>
              :
              <span className="ml-1">
                <label>Last</label>
                <Input style={{width:'3em'}} value={this.state.searchParams.lastxPeriod} type="number" name='lastxPeriod' onChange={this.handleChange} />
                {periodType.text}{this.state.searchParams.lastxPeriod > 1 && "s"}
              </span>
            }

            { this.state.searchParams.periodType === 2 &&
              <span className="ml-1">
                      <label className="mr-1">To:</label>
                      {!this.state.useCurrentDateTime &&
                        <DateInput
                          name="endDate"
                          className="ui input"
                          style={{width: '9em'}}
                          placeholder="Date"
                          value={moment(this.state.searchParams.endDate)}
                          iconPosition="left"
                          onChange={this.handleDateChange}
                          animation="fade"
                          popupPosition="bottom center"
                          closable
                          autoComplete="off"
                          dateFormat= {UIDateFormat}
                        />
                      }
                      { this.state.searchParams.periodType === 2 &&

                          <Checkbox checked={this.state.useCurrentDateTime} label='Current Date' onChange={this.handleUseCurrentDateTime} />
                        
                      }

                    </span>


              }

              <Checkbox className="ml-1" checked={this.state.showAllCollections} label='All Collections' onChange={() => this.setState({ showAllCollections: !this.state.showAllCollections })} />

              { this.props.filters.filterSelected.site.id > 0 && <Button className="ml-1" size='small' onClick={this.updateCollections} disabled={(!this.props.installation && !this.state.showAllCollections) || this.state.loading} positive>Refresh</Button> }


            </Grid.Column>
          </Grid.Row>
        </Grid>
        </form>
    )
  }

  render() {
    const showSummary = this.props.filters.filterSelected.operator && this.state.showSummary;

    return (
      <Grid className="height-100 gs-flex-container-vertical">
        <Grid.Row columns='equal'>
          <Grid.Column width="16">
            { this.searchSegment() }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="flex-stretch">
            { showSummary && 
              <Grid.Column computer="6" largeScreen="6" widescreen="4" className="gs-flex-container-vertical">
                  <DataGridRequest
                    loading={this.state.loading}
                    headings={this.state.summaryOptions.grid.headerDef}
                    data={this.state.summaryOptions.grid.data}
                    rowDef={this.state.summaryOptions.grid.rowDef}
                    hideOverflow
                    disableSort={true}
                    disableActiveRow={true}
                    onOptionChange={this.handleOptionChange}
                  />
              </Grid.Column>
            }
            <Grid.Column computer={ showSummary ? "9" : "16" } largeScreen={ showSummary ? "10" : "16" } widescreen={ showSummary ? "12" : "16" } className="gs-flex-container-vertical">
            <CollectionDetailsModal
              modalOpen={this.state.modalOpen}
              modalData={this.state.selectedCollection}
              handleClose={() => this.setState({modalOpen: false})}
              onChange={this.handleShowEventsChange}
              showEvents={this.state.showEvents}
              loading={this.state.loading}
            />
            <DataGridRequest
              loading={this.state.loading}
              headings={this.state.collections.grid.headerDef}
              rowDef={this.state.collections.grid.rowDef}
              data={this.state.collections.grid.data}
              onRowClick={this.handleRowClick}
              stretched
              fullHeight
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
};

const mapStateToProps = state => {
	return {
    filters: state.installationsFilters,
    installation: state.activeInstallation.installationId,
  };
};

export default connect(mapStateToProps, {
  fetchCollections,
  fetchCollectionByRef,
  fetchTransaction,
  fetchCollectionsFilters,
  emptyCollectionsSection
})(Collections);

